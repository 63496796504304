import type { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { calculateFee } from "@cosmjs/stargate";
import type { AccountAddress, BroadcastWsEvent, Collection, CollectionAddress, Launchpad, MintBlueprint, MintPlannedMetadata, Phase, StdPhase, Wallet, WalletAddress } from "@misei/globals/types";
import { GAS_SAFETY_FACTOR, MINT_GAS_PER_AMOUNT, TX_CLONES } from "@misei/globals/vars";
import { keccak_256 } from '@noble/hashes/sha3';
import { getSigningCosmWasmClient } from "@sei-js/core";
import { Buffer } from 'buffer';
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import dayjs from "dayjs";
import _, { lowerCase } from "lodash";
import { MerkleTree } from 'merkletreejs';


export type MintSimulation = ReturnType<MintCreator['getMintSimulation']>;


const hasWhitelist  = (p: Phase) => (p.merkleRoot !== null || p.whitelist.length > 0);
const isOnWhitelist = (p: Phase, w: Wallet) => hasWhitelist(p) ? p.whitelist?.includes(w.address) : true;


class Signer {

  client?: SigningCosmWasmClient;
  #wallet: Wallet;
  accountNumber: number = 0;
  sequenceNumber: number = 0;
  #init: boolean = false;
  static #map: Map<string, Signer> = new Map();

  constructor(wallet: Wallet) {
    this.#wallet = wallet;
  }


  static flushCache() { Signer.#map.clear(); }

  async lazyInit() {

    const store = useStore();

    if (!this.#init) {
      // @ts-expect-error
      this.client = await getSigningCosmWasmClient(store.rpc, this.#wallet.wallet!);
      const { sequence, accountNumber } = await this.client?.getSequence(this.#wallet.address)!;
      this.sequenceNumber = sequence;
      this.accountNumber  = accountNumber;
      this.#init          = true;
    }
  }

  static async getSigner(wallet: Wallet) {
    let signer = Signer.#map.get(wallet.address);
    if (!signer) {
      signer = new Signer(wallet);
      Signer.#map.set(wallet.address, signer);
    }
    await signer.lazyInit();
    return signer;
  }

}


export class MintCreator {

  #maxBatchSize:    number;
  #gasPrice:        number;
  #contractFee:     number;
  #tokensLeft:      number;
  #wallets:         Wallet[];
  #phases:          Phase[];
  #plannedMetadata: MintPlannedMetadata | null;
  #freeMintOnly:    boolean;

  constructor(
    maxBatchSize: number,
    gasPrice: number,
    contractFee: number,
    tokensLeft: number,
    wallets: Wallet[],
    phases:  Phase[],
    plannedMetadata: MintPlannedMetadata | null,
    freeMintOnly: boolean
  ) {
    this.#maxBatchSize    = maxBatchSize;
    this.#gasPrice        = gasPrice;
    this.#contractFee     = contractFee;
    this.#tokensLeft      = tokensLeft;
    this.#wallets         = wallets;
    this.#phases          = phases;
    this.#plannedMetadata = plannedMetadata;
    this.#freeMintOnly    = freeMintOnly;
  }


  // Sort mint phases by whitelist, start time, duration, unit price
  static sortPhases(phases: Phase[]) {

    return phases.sort((a, b) => {

      const getTime  = (date: string | Date | null) => date ? new Date(date).getTime() : 0;
      const hasWl    = (phase: Phase) => phase.whitelist?.length ?? 0 > 0;
      const duration = (phase: Phase) => {
        const [start, end] = [getTime(phase.startTime), getTime(phase.endTime)];
        return (start === 0 || end === 0) ? 0 : end - start;
      };

      // Whitelist criteria (whitelist first)
      const s1 = (hasWl(b) ? 1 : 0) - (hasWl(a) ? 1 : 0);
      if (s1 !== 0) return s1;

      // Start time criteria (earlier first)
      const s2 = getTime(a.startTime) - getTime(b.startTime);
      if (s2 !== 0) return s2;

      // Duration criteria
      const s3 = duration(a) - duration(b);
      if (s3 !== 0) return s3;

      // Unit price criteria
      const s4 = a.unitPrice - b.unitPrice;
      if (s4 !== 0) return s4;

      return 0;
    });
  }

  getPhaseUnitPrice(phase: Phase) {
    return this.#freeMintOnly ? 0 : phase.unitPrice;
  }


  /**
   * @description
   * The the bag size and the cost of the batch, a wallet
   * can afford to buy based on the wallet balance and item price.
   */
  getBatchSize(maxQty: number, itemPrice: number, walletBalance: number) {

    // We fill the bag up to it's max size and stop if we don't have more items to put it in.
    const maxBatchSize  = Math.min(this.#maxBatchSize, maxQty);
    const priceGradient = _.range(1, maxBatchSize + 1).map(qty => getMintCost(qty, itemPrice, this.#contractFee, getMintTxGasLimit(qty), this.#gasPrice));
    const batchSize     = priceGradient.reduce((p, c, i) => c > walletBalance ? p : i, -1) + 1;

    if (batchSize === 0) return { size: 0, cost: 0 };
    return { size: batchSize, cost: priceGradient[batchSize - 1] };
  }


  getMintSimulation(tokensWanted: number) {

    const mintSimulation: {
      totalCartSize: number,
      totalCost:     number,
      phases: {
        [phase: string]: {
          phase:     Phase,
          totalCost: number,
          cartSize:  number,
          walletTokenLimit: number,
          txBlueprints: { [wallet: string]: { phase: Phase, wallet: Wallet, batches: number[] } }
        }
      },
    } = { totalCost: 0, totalCartSize: 0, phases: {} };

    // List of wallet we can mutate during the process
    /**
     * @important
     * Deduct from the balance of the wallets the predicted
     * spending based on the previous txs.
     */
    const wallets = _.cloneDeep(this.#wallets).map(wallet => {
      const projectedSpending = this.#plannedMetadata?.wallets[wallet.address]?.plannedSpendings ?? 0;
      return { ...wallet, balance: wallet.balance - projectedSpending };
    });
    let tokenBuyable = Math.min(tokensWanted, this.#tokensLeft - (this.#plannedMetadata?.amount ?? 0));



    const getCartSize = (phase: Phase, wallet: Wallet) => {
      return mintSimulation.phases[phase.name]
        .txBlueprints[wallet.address]
        .batches.reduce((p, c) => p + c, 0);
    }

    const getPhaseWallets = (phase: Phase) => {

      const minCostForPhase = getMintCost(
        1,
        this.getPhaseUnitPrice(phase),
        this.#contractFee,
        getMintTxGasLimit(1),
        this.#gasPrice
      );

      return wallets.filter(wallet => isOnWhitelist(phase, wallet) && wallet.balance > minCostForPhase);
    };



    for (const phase of MintCreator.sortPhases(this.#phases)) {

      mintSimulation.phases[phase.name] = {
        phase,
        totalCost: 0,
        cartSize: 0,
        txBlueprints: {},
        walletTokenLimit: phase.maxTokens === 0 ? Infinity : phase.maxTokens,
      };

      const phaseBill    = mintSimulation.phases[phase.name];
      const phaseWallets = getPhaseWallets(phase);


      /**
       * @description
       * Robin hood algorithm to fill the wallets
       * with the maximum amount of tokens in a spreaded
       * manner.
       */
      while (true) {

        for (const wallet of phaseWallets) {

          if (!phaseBill.txBlueprints[wallet.address]) {
            phaseBill.txBlueprints[wallet.address] = { wallet, batches: [], phase };
          }


          /**
           * @important
           * We get the amount of tokens already scheduled
           * for the current wallet in the current phase.
           */
          const alreadyScheduledAmount = this.#plannedMetadata
            ?.wallets[wallet.address]
            ?.phases?.find(p => p.stdName === lowerCase(phase.name))?.amount ?? 0;

          /**
           * @description
           * The maximum amount of tokens we can cart
           * for the current wallet:
           * · The wallet token limit for the phase (if any).
           * · The tokens we want to buy (or the remaining tokens).
           */
          const currentTokenLimit = Math.max(Math.min(
            Math.max(0, phaseBill.walletTokenLimit) - getCartSize(phase, wallet) - alreadyScheduledAmount, // TODO: Explain this
            tokenBuyable,
          ), 0);

          /**
           * @description
           * If this wallet can't buy any more tokens for the
           * current phase, we remove it from the list.
           */
          if (currentTokenLimit === 0) {
            _.remove(phaseWallets, (w) => w.address === wallet.address);
            continue;
          }

          const batch = this.getBatchSize(currentTokenLimit, this.getPhaseUnitPrice(phase), wallet.balance);

          /**
           * @description
           * If the batch size is 0, this means
           * that the wallet can't afford to buy
           * any more tokens at ALL, so we remove
           * it from the current phase AND the global
           * wallets list.
           */
          if (batch.size === 0) {
            _.remove(phaseWallets, (w) => w.address === wallet.address);
            _.remove(wallets, (w) => w.address === wallet.address);
            continue;
          }


          wallet.balance           -= batch.cost;
          phaseBill.totalCost      += batch.cost;
          phaseBill.cartSize       += batch.size;
          mintSimulation.totalCartSize  += batch.size;
          mintSimulation.totalCost      += batch.cost;
          tokenBuyable             -= batch.size;

          phaseBill.txBlueprints[wallet.address].batches.push(batch.size);
        }


        if (phaseWallets.length === 0) break;

      }

    }

    return mintSimulation;
  }


  async generateTxs(txsData: { phase: Phase, wallet: Wallet, batches: number[] }[], collection: Collection, defaultBroadcastTime: Date) {

    const txs: MintBlueprint['txs'][0][] = [];
    const projectedSequencesMap = await getProjectedSequences(_.uniq(txsData.map(tx => tx.wallet.address)));

    for (const { phase, wallet, batches } of txsData) {

      const signer = await Signer.getSigner(wallet);

      /**
       * @important
       * If we have a predicted sequence number for the wallet,
       * we override the one the chain returned.
       */
      if (projectedSequencesMap[wallet.address]) {
        signer.sequenceNumber = projectedSequencesMap[wallet.address];
        delete projectedSequencesMap[wallet.address]; // Delete it to avoid using it again (IMPORTANT)
      }


      for (const amount of batches) {

        const { merkleProof, hashedAddress } = await getMerkleProofData(collection, phase, wallet.address);
        const gasLimit = getMintTxGasLimit(amount);
        const fee      = calculateFee(gasLimit, `${this.#gasPrice}usei`);
        const nonce    = `Minted with https://misei.bot — ${new Date().getTime().toString()}`;
        const mintMsgs = await getMintTxMsgs(
          phase,
          collection.minterAddress,
          collection.address,
          this.#contractFee,
          wallet.address,
          amount,
          merkleProof,
          hashedAddress,
          collection.launchpad
        );

        let paidTxClones: string[] = [], freeTxClones: string[] = [];


        let offset = -TX_CLONES;
        const originalClones = await Promise.all(new Array(TX_CLONES * 2 + 1).fill(true).map(async () => {

          const seqOffset = offset;
          offset++; // To not suffer from race conditions

          const tx = await signer.client!.sign(wallet.address, mintMsgs, fee, nonce, {
            chainId: collection.chainId,
            accountNumber: signer.accountNumber,
            sequence: signer.sequenceNumber + seqOffset,
          });

          return Buffer.from(TxRaw.encode(tx!).finish()).toString('base64');
        }));


        /**
         * We route the 'original' clones ie the clones generated from
         * the unalterated phase data into the right array based on
         * the phase unit price.
         */
        if (phase.unitPrice > 0) paidTxClones = originalClones;
        else freeTxClones = originalClones;


        // If mint is not free, generate clones of the tx without the funds for hypothetical free mint
        if (phase.unitPrice > 0) { // TODO:

          let freeOffset = -TX_CLONES;
          freeTxClones = await Promise.all(new Array(TX_CLONES * 2 + 1).fill(true).map(async () => {

            // We generate a clone of the tx without the funds for hypothetical free mint
            const freeMintMsgs = _.cloneDeep(mintMsgs).map(msg => { delete msg.value.funds; return msg; });
            const seqOffset = freeOffset;
            freeOffset++; // To not suffer from race conditions

            const tx = await signer.client!.sign(wallet.address, freeMintMsgs, fee, nonce, {
              chainId: collection.chainId,
              accountNumber: signer.accountNumber,
              sequence: signer.sequenceNumber + seqOffset,
            });

            return Buffer.from(TxRaw.encode(tx!).finish()).toString('base64');
          }));
        }

        signer.sequenceNumber++; // Update sequence now


        /**
         * @important
         * Sometime the user will try to mint in a phase that
         * has already started, in that case we set broadcast
         * time to now.
         */
        const broadcastTime = dayjs().isAfter(phase.startTime) ? null : phase.startTime;


        /**
         * @description
         * Generate a unique id for the tx pool item
         * based on the hash of the tx clones.
         */
        const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(paidTxClones.concat(freeTxClones).join('')));
        const hashArray  = Array.from(new Uint8Array(hashBuffer));
        const hashHex    = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        const totalCost  = getMintCost(amount, this.getPhaseUnitPrice(phase), this.#contractFee, gasLimit, this.#gasPrice);
        const itemsCost  = this.getPhaseUnitPrice(phase) * amount;


        txs.push({
          id: hashHex,
          broadcastTime,
          stdPhase: phase.name,
          wallet: wallet.address,
          paidClones: paidTxClones,
          freeClones: freeTxClones,
          sequenceNumber: signer.sequenceNumber,

          blueprint: {
            stdPhase: lowerCase(phase.name),
            unitPrice: phase.unitPrice,
            itemsCost,
            totalCost,
            amount,
            gasPrice: this.#gasPrice,
            gasLimit,
            msgs: mintMsgs,
            launchpad: collection.launchpad,
            phaseIsWhitelisted: hasWhitelist(phase),
            endTime: phase.endTime,
            contractFee: this.#contractFee,
            gasFee: parseInt(fee.amount[0].amount),
            freeMintOnly: this.#freeMintOnly,
            clonesCount: TX_CLONES * 2 + 1
          },
        });


      }
    }

    Signer.flushCache(); // So it's not keeping the sequence numbers in memory

    return txs;
  }


  async getMintBlueprint(mintSimulation: MintSimulation, collection: Collection, account: AccountAddress): Promise<MintBlueprint> {

    const defaultBroadcastTime = new Date(new Date().toISOString()); // Give UTC time

    const txsData = Object.keys(mintSimulation.phases).flatMap(phaseName => {
      const txBlueprints = mintSimulation.phases[phaseName].txBlueprints;
      return Object.keys(txBlueprints).flatMap(walletAddress => txBlueprints[walletAddress]);
    });

    const txs = await this.generateTxs(txsData, collection, defaultBroadcastTime);

    return {
      account,
      collection: collection.address,
      totalWanted: mintSimulation.totalCartSize, // TODO:
      txs,
    };

  }


  /**
   * @important
   * In the case when the mint order is large, we split it into
   * pieces to avoid issues with the data limit of AWS.
   */
  static getChunkedMintBlueprint(mintBlueprint: MintBlueprint) {

    const { account, collection, totalWanted, txs } = mintBlueprint;
    const mintOrderChunks: MintBlueprint[] = [];
    const txsPool = [...mintBlueprint.txs];
    const cutoffSizeInMb = 5; // 5MB (have 1MB safety margin)
    const calculateSize  = (str: string) => new Blob([str]).size / (1024 * 1024);
    let currentMintOrderChunk: MintBlueprint = { account, collection, totalWanted, txs: [] };


    // If the mint order is small enough, we return it as is.
    if (calculateSize(JSON.stringify(mintBlueprint)) < cutoffSizeInMb) return [mintBlueprint];


    // Continue chunking until txsPool is empty
    while (txsPool.length > 0) {

      /**
       * We add tx one by one to the current chunk until
       * the size of the chunk is greater than the cutoff.
       */
      const tx = txsPool.shift()!;
      const txSize = calculateSize(JSON.stringify(tx));
      const mintOrderChunkSize = calculateSize(JSON.stringify(currentMintOrderChunk));


      /**
       * @important
       * If adding a tx to the current chunk still doesn't
       * exceed the cutoff, we add it and continue.
       */
      if ((mintOrderChunkSize + txSize) < cutoffSizeInMb) currentMintOrderChunk.txs.push(tx);
      else {
        mintOrderChunks.push(currentMintOrderChunk);
        currentMintOrderChunk = { account, collection, totalWanted, txs: [tx] };
      }

    }

    if (currentMintOrderChunk.txs.length > 0) mintOrderChunks.push(currentMintOrderChunk);

    return mintOrderChunks;
  }


  get maxMintableTokens() {
    const mintQuote = this.getMintSimulation(Infinity);
    return mintQuote.totalCartSize;
  }

}



/**
 * @description
 * Utilities used during the mint preparation process.
 */
const hashAddress = (address: string) => Array.from(Buffer.from(keccak_256(address)));
const getMintTxGasLimit = (amount: number) => amount !== 0 ? Math.ceil(MINT_GAS_PER_AMOUNT[amount - 1] * GAS_SAFETY_FACTOR) : 0;

function getMintCost(amount: number, unitPrice: number, contractFee: number, gasLimit: number, gasPrice: number) {
  if (amount === 0) return 0;
  const actualContractFee = unitPrice === 0 ? 0 : contractFee;
  const contractCost = amount * (actualContractFee + unitPrice);
  const gasCost  = gasLimit * gasPrice;
  return Math.ceil(contractCost + gasCost);
}

async function getMerkleProofData(collection: Collection, phase: Phase, wallet: WalletAddress) {

  if (collection.launchpad === 'SEIMURAI') {
    const { data, status } = await getSeimuraiMerkleProofData(collection.address, wallet, collection.launchpad);
    if (status === 'fail') throw new SeimuraiApiError(collection.address);
    const phaseData = data.find(p => lowerCase(p.name).trim() === phase.stdName);
    if (!phaseData) throw new Error(`Could not load merkle proof data for phase ${phase} of collection ${collection.address}`);
    const { merkleProof = null, hashedAddress = null } = phaseData;
    return { merkleProof, hashedAddress };
    // isWhitelisted: phaseData.isWhitelisted, // TODO: Is it needed?
  }

  else if (['LIGHTHOUSE', 'DAISY'].includes(collection.launchpad)) {
    if (!phase.merkleRoot) return { merkleProof: null, hashedAddress: null };
    const hashedWlWallets = phase.whitelist.map(keccak_256);
    const tree = new MerkleTree(hashedWlWallets, keccak_256, { sortPairs: true });
    const merkleProof = tree.getProof(Buffer.from(keccak_256(wallet))).map(e => Array.from(e.data));
    const hashedAddress = hashAddress(wallet);
    return { merkleProof, hashedAddress };
  }

  else if (['MRKT', 'FRENSEI'].includes(collection.launchpad)) { // Not needed for MRKT & Frensei
    return { merkleProof: null, hashedAddress: null };
  }

  else throw new Error(`Unsupported launchpad type: ${collection.launchpad}`);
}

async function getMintTxMsgs(
  phase: Phase,
  minterContract: string,
  collection: CollectionAddress,
  contractFee: number,
  senderAddress: string,
  amount: number,
  merkleProof: number[] | null,
  hashedAddress: number[] | null,
  launchpad: Launchpad
) {

  const baseMsg = (msg: Record<string, any>, funds: number) => ({
    typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
    value: {
      sender: senderAddress,
      contract: minterContract,
      msg: btoa(JSON.stringify(msg)),
      funds: funds > 0 ? [{
        denom: 'usei',
        amount: funds.toString()
      }]: [],
    }
  });

  if (launchpad === 'SEIMURAI') {
    return [baseMsg(
      {
        mint: {
          collection,
          group:          phase.name,
          recipient:      senderAddress,
          merkle_proof:   merkleProof,
          hashed_address: hashedAddress,
          max_amount:     amount,
        }
      },
      phase.unitPrice > 0 ? (phase.unitPrice * amount) + contractFee : 0
    )];
  }


  else if (launchpad === 'MRKT') {
    const msg = await getMRKTMintMessage(collection, senderAddress, phase.name);
    return new Array(amount).fill(true).map(() => baseMsg(
      msg,
      phase.unitPrice > 0 ? phase.unitPrice + contractFee : 0
    ));
  }

  else if (launchpad === 'FRENSEI') {
    const msg = await getFrenseiMintMessage(collection, senderAddress, phase.extra?.frenseiPhaseId);
    return new Array(amount).fill(true).map(() => baseMsg(
      msg,
      phase.unitPrice > 0 ? phase.unitPrice + contractFee : 0
    ));
  }


  // For Lighthouse and Daisy
  return new Array(amount).fill(true).map(() => baseMsg(
    {
      mint_native: {
        collection:     collection,
        group:          phase.name,
        recipient:      senderAddress,
        merkle_proof:   merkleProof,
        hashed_address: hashedAddress,
      },
    },
    phase.unitPrice > 0 ? phase.unitPrice + contractFee : 0)
  );

}


export function onBroadcasterUpdatesEvent(payload: BroadcastWsEvent) {

  const store = useStore();
  const { mintStatus, collection, minted, phases, wallets, txs } = payload;

  const mintIndex = store.mints.findIndex(m => m.collection.address === collection);
  const mint = store.mints[mintIndex];

  mint.status = mintStatus;

  // Update minted tokens
  if (minted) {
    mint.minted = _.uniqBy([...mint.minted, ...minted], 'tokenId');
  }

  // Update wallets status
  if (wallets) {
    for (const wallet of wallets) {
      const walletIndex = mint.wallets.findIndex(w => w.address === wallet.address);
      const mintWallet  = mint.wallets[walletIndex];
      if (mintWallet) mint.wallets[walletIndex] = { ...mintWallet, status: wallet.status };
    }
  }

  // Update txs status
  if (txs) {
    for (const tx of txs) {
      const txIndex = mint.txs.findIndex(t => t.id === tx.id);
      const mintTx  = mint.txs[txIndex];
      if (mintTx) {
        mint.txs[txIndex] = {
          ...mintTx,
          hash: tx.hash,
          success: tx.success,
          broadcastStatus: tx.broadcastStatus,
          cancelReason: tx.cancelReason,
          errorReason: tx.errorReason,
          broadcastErrorCode: tx.broadcastErrorCode,
          contractTxStatus: tx.contractTxStatus,
          totalCost: tx.totalCost,
          gasCost: tx.gasCost,
        };
      }
    }
  }


  store.mints[mintIndex] = mint;
}
