import { PlanId } from "@misei/globals/types";

export const MINT_GAS_PER_AMOUNT = [
  311676,   // x1 tokens
  568300,   // x2 tokens
  824993,   // x3 tokens
  1081755,  // x4 tokens
  1338590,  // x5 tokens
  1595492,  // x6 tokens
  1852462,  // x7 tokens
  2109501,  // x8 tokens
  2366649,  // x9 tokens
  2624036,  // x10 tokens
  2881529,  // x11 tokens
  3139125,  // x12 tokens
  3396825,  // x13 tokens
  3654627,  // x14 tokens
  3912532,  // x15 tokens
  4170538,  // x16 tokens
  4428646,  // x17 tokens
  4686861,  // x18 tokens
  4945178,  // x19 tokens
  5203598,  // x20 tokens
  5462130,  // x21 tokens
  5720765,  // x22 tokens
  5979502,  // x23 tokens
  6238336,  // x24 tokens
  6497272,  // x25 tokens
  6756318,  // x26 tokens
  7015466,  // x27 tokens
  7274717,  // x28 tokens
  7534070,  // x29 tokens
  7793526,  // x30 tokens
  8053085,  // x31 tokens
  8312746,  // x32 tokens
  8572517,  // x33 tokens
  8832394,  // x34 tokens
  9092363,  // x35 tokens
  9352435,  // x36 tokens
  9612609,  // x37 tokens
  9872886   // x38 tokens
];

export const BANK_TRANSFER_GAS_PER_AMOUNT = [
  82959,
  97545,
  112131,
  126717,
  141303,
  155889,
  170475,
  185061,
  199647,
  214263,
  228882,
  243501,
  258120,
  272739,
  287358,
  301977,
  316596,
  331215,
  345834,
  360453,
  375072,
  389691,
  404310,
  418929,
  433548,
  448167,
  462786,
  477405,
  492024,
  506643,
  521262,
  535881,
  550500,
  565119,
  579738,
  594357,
  608976,
  623595,
  638214,
  652833,
  667452,
  682071,
  696690,
  711309,
  725928,
  740547,
  755166,
  769785,
  784404,
  799023,
  813642,
  828261,
  842880,
  857499,
  872118,
  886737,
  901356,
  915975,
  930594,
  945213,
  959832,
  974451,
  989070,
  1003689,
  1018308,
  1032927,
  1047546,
  1062165,
  1076784,
  1091403,
  1106022,
  1120641,
  1135260,
  1149879,
  1164498,
  1179117,
  1193736,
  1208355,
  1222974,
  1237593,
  1252212,
  1266831,
  1281450,
  1296069,
  1310688,
  1325307,
  1339926,
  1354545,
  1369164,
  1383783,
  1398402,
  1413021,
  1427640,
  1442259,
  1456878,
  1471497,
  1486116,
  1500735,
  1515354,
  1530003,
  1544655,
  1559307,
  1573959,
  1588611,
  1603263,
  1617915,
  1632567,
  1647219,
  1661871,
  1676523,
  1691175,
  1705827,
  1720479,
  1735131,
  1749783,
  1764435,
  1779087,
  1793739,
  1808391,
  1823043,
  1837695,
  1852347,
  1867009,
  1881661,
  1896313,
  1910965,
  1925617,
  1940269,
  1954921,
  1969573,
  1984225,
  1998877,
  2013529,
  2028181,
  2042833,
  2057485,
  2072137,
  2086789,
  2101441,
  2116093,
  2130745,
  2145397,
  2160049,
  2174701,
  2189353,
  2204005,
  2218657,
  2233309,
  2247961,
  2262613,
  2277265,
  2291917,
  2306569,
  2321221,
  2335873,
  2350525,
  2365177,
  2379829,
  2394481,
  2409133,
  2423785,
  2438437,
  2453089,
  2467741,
  2482393,
  2497045,
  2511697,
  2526349,
  2541001,
  2555653,
  2570305,
  2584957,
  2599609,
  2614261,
  2628913,
  2643565,
  2658217,
  2672869,
  2687521,
  2702173,
  2716825,
  2731477,
  2746129,
  2760781,
  2775433,
  2790085,
  2804737,
  2819389,
  2834041,
  2848693,
  2863345,
  2877997,
  2892649,
  2907301,
  2921953,
  2936605,
  2951257,
  2965909,
  2980561,
  2995213
];

// Safety factor for gas estimation
export const GAS_SAFETY_FACTOR = 2;


export const TX_CLONES = 5;

export const DISCORD_INVITE_LINK = 'https://discord.gg/misei';

export const SEIMURAI_CA   = 'sei178a7twg28c6zp45xjzu2jj7zaguxvkh70xhsvwvvjwfjgcegv78qcmfz90';
export const LIGHTHOUSE_CA = 'sei1hjsqrfdg2hvwl3gacg4fkznurf36usrv7rkzkyh29wz3guuzeh0snslz7d';
export const MRKT_CA       = 'sei19dy87y9px8q265lz75pvgvsxfurhc73zklxgwwunxluy9e0xf88quq7e9s';
export const PALLET_CA     = 'sei152u2u0lqc27428cuf8dx48k8saua74m6nql5kgvsu4rfeqm547rsnhy4y9';

export const TESTNET_RPC = 'https://rpc.atlantic-2.seinetwork.io';

export const PLANS = {
  WEEKLY: {
    usdAmount: 20,
    durationInDays: 7,
  },
  MONTHLY: {
    usdAmount: 50,
    durationInDays: 30,
  },
} satisfies Record<PlanId, {
  usdAmount: number,
  durationInDays: number,
}>;
