import { fromHex } from "@cosmjs/encoding";
import { DirectSecp256k1Wallet } from "@cosmjs/proto-signing";
import type { ExposedAccount, Network, SummarizedMint, Wallet } from "@misei/globals/types";
import { TESTNET_RPC } from '@misei/globals/vars';
import { COMPASS_WALLET, KEPLR_WALLET, LEAP_WALLET, getCosmWasmClient, restoreWallet, type SeiWallet } from "@sei-js/core";
import * as Sentry from "@sentry/vue";
import { PromisePool } from "@supercharge/promise-pool";
import Crypto from 'crypto-js';
import { defineStore } from 'pinia';
import type { PromiseType } from 'utility-types';



const emptyAccount: ExposedAccount = {
  address:    '',
  pfp:        '',
  isVerified: false,
  isInGuild:  false,
  subscription: { active: false, until: null },
};



export type ColdStorage  = { [key: string]: string };
export type Memory       = { wallets: Wallet[] }
export type StoreAccount = ExposedAccount & {
  hash: string, key: string, walletInstance: SeiWallet | null
};

interface State {

  // Page loading
  pageLoading: boolean,

  // Modal flags
  modals: {
    signatureRequired:       boolean,
    differentAccountWarning: boolean,
  },

  // Connection
  waitWalletConn:  boolean,
  waitWalletSign:  boolean,
  selectedWallet:  SeiWallet | null,
  prevConnAddr:    string | null,
  prevConnWallet:  string | null,
  customsRequired: boolean, // Whether the user has to go through customs

  // Wallet Management
  autoWalletRefresh:     boolean,
  walletRefreshInterval: NodeJS.Timeout | null,

  // Storage
  mints:           SummarizedMint[],
  account:         StoreAccount,
  coldStorage:     ColdStorage,
  memory:          Memory,

  // Rpc Communication
  rpcClient:       PromiseType<ReturnType<typeof getCosmWasmClient>> | null,

}

export const useStore = defineStore('store', {

  state: (): State => ({
    modals: { signatureRequired: false, differentAccountWarning: false },
    account: { hash: '', key:  '', walletInstance: null, ...emptyAccount },
    mints: [],
    walletRefreshInterval: null,
    autoWalletRefresh: false,
    pageLoading: false,
    waitWalletConn: false,
    waitWalletSign: false,
    selectedWallet: null,
    prevConnAddr: localStorage.getItem('misei.connectedAddress'),
    prevConnWallet: localStorage.getItem('misei.walletConnected'),
    customsRequired: false,
    rpcClient: null,
    coldStorage: {},
    memory: { wallets: [] },
  }),


  getters: {
    hasAccess:      ({ account }) => account.subscription.active,
    network()       { return ["prod", "edge"].includes(useRuntimeConfig().public.stage) ? "mainnet" : "testnet" },
    pendingMints:   ({ mints }) => mints.filter((mint) => mint.status === 'PENDING'),
    processedMints: ({ mints }) => mints.filter((mint) => mint.status === 'PROCESSED'),
    wallets:        ({ memory }) => (memory.wallets?.filter((wallet) => !!wallet.wallet) ?? []) as Wallet[],
    addresses:      ({ memory }) => memory.wallets?.map((wallet) => wallet.address) ?? [],
    chainId()       { return this.network === 'mainnet' ? 'pacific-1' : 'atlantic-2'; },
    rpc()           { return this.network === 'mainnet' ? 'https://rpc.sei-apis.com' : TESTNET_RPC; },
    isLoggedIn:     ({ account }) => !!account.hash && !!account.address && !!account.key,
  },


  actions: {

    async getWalletBalance(address: string) {
      while (true) {
        try {
          const balance = await this.rpcClient!.getBalance(address, 'usei');
          return parseInt(balance.amount ?? 0);
        } catch (err) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    },

    // Wallet Management
    async addWallet(rawWallet: Omit<Wallet, 'client' | 'refreshBalance' | 'wallet' | 'balance'>, options: { balanceFetch: boolean, insertInMemory: boolean } = { balanceFetch: true, insertInMemory: true }) {

      const that = this;

      const wallet = {
        ...rawWallet,
        balance: options.balanceFetch ? await this.getWalletBalance(rawWallet.address) : 0,
        wallet: await this.instantiateWallet(rawWallet.genesis),
        async refreshBalance() {
          this.balance = await that.getWalletBalance(this.address);
        },
      };

      if (options.insertInMemory) {
        this.memory.wallets.push(wallet);
        this.freezeMemory();
      }

      return wallet;
    },

    updateWalletName(address: string, name: string) {
      const wallet = this.memory.wallets.find((w) => w.address === address);
      if (!wallet) return;
      wallet.name = name;
      this.freezeMemory();
    },

    removeWallets(walletsToRemove: Wallet[]) {
      this.memory.wallets = this.memory.wallets.filter((wallet) => {
        return !walletsToRemove.map((w) => w.address).includes(wallet.address);
      });
      this.freezeMemory();
    },

    async instantiateWallet(genesis: Wallet['genesis']) {
      const { type, value } = genesis;
      return type === 'pk' ?
        await DirectSecp256k1Wallet.fromKey(fromHex(value), 'sei') :
        await restoreWallet(value);
    },


    // Memory Management
    async warmMemory() {

      // if (!this.isLoggedIn) return;
      const coldStorage = localStorage.getItem(this.account.hash);
      if (!coldStorage) {
        this.memory = { wallets: [] };
        return;
      }

      const serializedMemory = Crypto.AES.decrypt(coldStorage!, this.account.key!).toString(Crypto.enc.Utf8);
      this.memory = JSON.parse(serializedMemory) as Memory;

      console.log("COPY THIS YOU RETARD", JSON.stringify(this.memory));

      // Instantiate wallets
      await Promise.all(this.memory.wallets.map(async (wallet) => {
        wallet.wallet      = await this.instantiateWallet(wallet.genesis);
        wallet.isFavourite = !!wallet.isFavourite;
        wallet.refreshBalance = async () => {
          wallet.balance = await this.getWalletBalance(wallet.address);
        };
      }));

    },

    freezeMemory() {
      const serializedMemory = JSON.stringify(this.memory);
      const encryptedMemory  = Crypto.AES.encrypt(serializedMemory, this.account.key!).toString();
      localStorage.setItem(this.account.hash, encryptedMemory);
    },


    async refreshWalletBalances() {
      await PromisePool
        .withConcurrency(20)
        .for(this.memory.wallets)
        .process(async (wallet) => { await wallet.refreshBalance?.(); });
    },

    /**
     * Toggle the favorite status of a wallet.
     * @param address
     * @returns
     */
    toggleFavoriteWallet(address: string) {
      const walletIndex = this.memory.wallets.findIndex((w) => w.address === address);
      if (walletIndex === -1) return;
      const wallet = this.memory.wallets[walletIndex];
      wallet.isFavourite = !wallet.isFavourite;
      console.log(wallet.isFavourite);
      this.memory.wallets[walletIndex] = wallet;
      this.freezeMemory();
    },

    /**
     * @description
     * Helper function to save the wallet connected and the address
     * of the user in the local storage. Can be used to reset too.
     */
    saveConnectionData(walletKey: string, address: string) {

      if (!walletKey) localStorage.removeItem('misei.walletConnected');
      else localStorage.setItem('misei.walletConnected', walletKey);

      if (!address) localStorage.removeItem('misei.connectedAddress');
      else localStorage.setItem('misei.connectedAddress', address);

      this.prevConnAddr   = address;
      this.prevConnWallet = walletKey;
    },

    // Alias
    resetConnectionData() { this.saveConnectionData('', ''); },

    /**
     * @description
     * Return the SeiWallet instance of the wallet connected of
     * the given name.
     */
    getWalletInstance(walletKey: string) {
      if (!walletKey) return null;
      const walletInstance = {
        keplr: KEPLR_WALLET,
        compass: COMPASS_WALLET,
        leap: LEAP_WALLET
      }[walletKey as 'keplr' | 'compass' | 'leap'];
      if (!walletInstance) throw new Error(`Wallet instance not found for name ${walletKey}`);
      return walletInstance;
    },

    /**
     * @description
     * If the user wants to change the connected wallet, this method
     * will reset the current wallet so the user can connect a new one.
     */
    async resetConnectedWallet() {

      const toast  = useToast();
      const wallet = this.getWalletInstance(this.prevConnWallet!);

      if (wallet) {
        if (wallet.walletInfo.windowKey === 'keplr') {
          // Keplr doesn't support the .disconnect() method
          // @ts-expect-error
          await window.keplr.disable();
        } else await wallet.disconnect(this.chainId);
      }

      this.resetConnectionData();

      toast.add({
        title: `Success!`,
        description: `You can now connect a new wallet.`,
        timeout: 4000,
        icon: "i-heroicons-check-circle"
      });

    },

    /**
     * @description
     * Helper method to log a user back in without him having to
     * select the wallet again.
     */
    async autoConnect() {

      const toast     = useToast();
      const walletKey = this.prevConnWallet;
      const wallet    = this.getWalletInstance(walletKey!);

      /**
       * If we couldn't find the wallet, it could be that the user
       * uninstalled it or something similar. In that case we reset
       * the connected wallet and let the user connect a new one.
       */
      if (!wallet) {

        this.resetConnectionData();

        toast.add({
          title: `Error`,
          description: `Could not find the wallet you originally connected with. Please connect a new one.`,
          timeout: 10000,
          icon: "i-heroicons-x-mark"
        });

        return;
      }

      await this.connect(wallet);
    },

    /**
     * @description
     * Called when the user clicks on the "Connect Wallet" button.
     * @note This method only connects the wallet to the app, it does not
     * sign the login message nor does it load the user's account.
     */
    async connect(wallet: SeiWallet, onConnect?: () => void){

      const config = useRuntimeConfig();
      const connectedAddress = this.prevConnAddr

      if (!wallet.connect) throw new Error('Wallet does not support connect');

      this.waitWalletConn = true;

      try {

        // TODO: Explain
        if (!connectedAddress && wallet.disconnect) {
          if (wallet.walletInfo.windowKey === 'keplr') {
            // Keplr doesn't support the .disconnect() method
            // @ts-expect-error
            await window.keplr.disable();
          } else await wallet.disconnect(this.chainId);
        }


        await wallet.connect(this.chainId);
        const [account] = await wallet.getAccounts(this.chainId);

        if (onConnect) onConnect(); // Call callback

        this.selectedWallet = wallet;

        if (!connectedAddress) { // First time connecting
          this.modals.signatureRequired = true;
        }
        else if (connectedAddress !== account.address) { // Different account
          // TODO: Display a warning message modal
          // TODO: Give the possibility to the user to logout or continue
        }
        else { // Same account
          await this.sign();
        }


        this.waitWalletConn = false;

      } catch (err: any) {
        this.selectedWallet = null;
        if (config.public.stage === 'local') console.error(err);
        if (['Request rejected', 'Transaction declined'].includes(err.message)) return;
        return Sentry.captureException(err);
      } finally {
        this.waitWalletConn = false;
      }

    },

    /**
     * @description
     * Called after the user's wallet has connected.
     */
    async sign() {

      const { public: { stage } } = useRuntimeConfig();
      const loginMessage = `Log into ${stage === "prod" ? "misei.bot" : stage === "edge" ? "edge.misei.bot" : "dev.misei.bot"}`;
      const wallet = this.selectedWallet!;

      if (!wallet.signArbitrary) throw new Error('Wallet does not support signArbitrary');

      this.waitWalletConn = false; // If we are here, the wallet is already connected
      this.waitWalletSign    = true;

      try {

        const [account] = await wallet.getAccounts(this.chainId);
        const signature = (await wallet.signArbitrary(this.chainId, account.address, loginMessage))?.signature ?? '';

        console.log("COPY THIS YOU RETARD", signature);

        this.saveConnectionData(wallet.walletInfo.windowKey, account.address);

        this.waitWalletSign = false;
        this.modals.signatureRequired = false;

        await this.login(account.address, signature);

      } catch (err: any) {
        if (stage === 'local') console.error(err);
        if (['Request rejected', 'Transaction declined'].includes(err.message)) return;
        return Sentry.captureException(err);
      } finally {
        this.waitWalletSign = false;
      }

    },


    // Account Management
    async login(accountAddress: string, signature: string) {

      this.rpcClient = await getCosmWasmClient(this.rpc);


      this.pageLoading = true;


      if (!signature) throw new Error('Could not generate signature.');

      console.log("Warning memories");
      await this.warmMemory();

      const account             = await getOrCreateAccount(accountAddress);
      this.account.hash         = Crypto.SHA256(accountAddress).toString();
      this.account.key          = signature;
      this.account.address      = accountAddress;
      this.account.pfp          = account.pfp;
      this.account.isVerified   = account.isVerified;
      this.account.isInGuild    = account.isInGuild;
      this.account.subscription = account.subscription;
      this.customsRequired      = !account.isVerified || !account.isInGuild;

      await this.warmMemory();
      await connectWebsocket();

      if (this.customsRequired) {
        this.pageLoading = false;
        return navigateTo('/customs');
      }

      await this.refreshWalletBalances();
      this.walletRefreshInterval = setInterval(async () => {
        if (!this.autoWalletRefresh) return;
        await this.refreshWalletBalances();
      }, 10000);

      this.pageLoading = false;

      navigateTo('/mints');
    },

    logout() {

      this.freezeMemory();

      // Reset store
      this.mints           = [];
      this.account         = {
        hash: '',
        key: '',
        walletInstance: null,
        ...emptyAccount
      };

      if (this.walletRefreshInterval) clearInterval(this.walletRefreshInterval!);
      disconnectWebsocket();
      navigateTo('/');
    },

  },

});
