import * as Sentry from '@sentry/vue';


let socket: WebSocket | null = null;

export function connectWebsocket() {

  if (!process.client) return;

  const config = useRuntimeConfig();
  const store  = useStore();
  const maxReconnectAttempts = 10;

  const connect = (attempt = 0) => {

    socket = new WebSocket(`${config.public.wsUrl}?account=${store.account.address}`);
    let interval: NodeJS.Timeout | null = null;

    socket.onmessage = ({ data }) => {
      const { event, payload } = JSON.parse(data);
      if (event === 'broadcaster@updates') onBroadcasterUpdatesEvent(payload);
    };

    socket.onopen = () => {
      interval = setInterval(() => { socket?.send("ping"); }, 30 * 1000); // Keep the websocket connection alive.
    };

    // socket.onclose = async (event) => {

    //   if (attempt >= maxReconnectAttempts) {
    //     Sentry.captureEvent({
    //       message: "Websocket connection closed after max reconnect attempts (onclose).",
    //       level: 'error',
    //       extra: { event }
    //     });
    //     return;
    //   }

    //   if (!event.wasClean) {
    //     Sentry.captureEvent({
    //       message: "Websocket connection closed unexpectedly (onclose).",
    //       level: 'error',
    //       extra: { event }
    //     });
    //   }

    //   if (interval) clearInterval(interval);
    //   await new Promise(resolve => setTimeout(resolve, 500)); // Wait 500ms before reconnecting.
    //   connect(attempt + 1);
    // };

    socket.onerror = async (error) => {

      if (attempt >= maxReconnectAttempts) {
        Sentry.captureEvent({
          message: "Websocket connection closed after max reconnect attempts (onerror).",
          level: 'error',
          extra: { error }
        });
        return;
      }


      Sentry.captureEvent({
        message: "Websocket connection closed unexpectedly (onerror).",
        level: 'error',
        extra: { error }
      });


      if (interval) clearInterval(interval);
      await new Promise(resolve => setTimeout(resolve, 500)); // Wait 500ms before reconnecting.
      connect(attempt + 1);
    };

  };

  connect();
}


export function disconnectWebsocket() {
  if (socket) {
    socket.close();
    socket = null;
  }
}
