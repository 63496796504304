import type { ApiResponse } from "@misei/core/response";
import type { Collection, CollectionAddress, ExposedAccount, Launchpad, MintBlueprint, MintPlannedMetadata, MultiWalletPortfolio, SummarizedMint, Tx, WalletAddress } from "@misei/globals/types";
import PromisePool from "@supercharge/promise-pool";
import _ from "lodash";
import qs from 'querystringify';



export const getCollection = async (url: string) => {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/launchpads/scan${qs.stringify({ url }, true)}`);
  return await response.json() as ApiResponse<Collection>;
};


export const getProjectedSequences = async (addresses: string[]) => {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/wallets/sequences`, {
    method: 'POST',
    body: JSON.stringify({ addresses }),
    headers: { 'Content-Type': 'application/json' },
  });
  const json = await response.json() as ApiResponse<{ [address: string]: number }>;
  return json.data;
};


export const createMint = async (blueprint: MintBlueprint) => {
  const config = useRuntimeConfig();
  await fetch(`${config.public.apiUrl}/minter/mint`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(blueprint)
  });
};


export const getMintPlannedMetadata = async (collection: CollectionAddress) => {
  const store    = useStore();
  const config   = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/minter/planned?collection=${collection}&account=${store.account.address}`);
  const json     = await response.json() as ApiResponse<MintPlannedMetadata>;
  return json.data;
};


export const getOrCreateAccount = async (address: string) => {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/accounts?address=${address}`);
  const json = await response.json() as ApiResponse<ExposedAccount>;
  return json.data;
};


export const getSummarizedMints = async (collection?: CollectionAddress) => {
  const store = useStore();
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/minter/summarizedMints${qs.stringify({
    account: store.account.address,
    ...(collection ? { collection } : {})
  }, true)}`);
  const json = await response.json() as ApiResponse<SummarizedMint[]>;
  return json.data;
};


export const cancelMintWallet = async (wallet: WalletAddress, collection: CollectionAddress) => {
  const store = useStore();
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/minter/cancelWallet`, {
    method: 'POST',
    body: JSON.stringify({
      account: store.account.address,
      collection,
      address: wallet,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  const json = await response.json() as ApiResponse<string[]>;
  return json.data;
};


export const cancelMint = async (collection: CollectionAddress) => {
  const store = useStore();
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/minter/cancelMint`, {
    method: 'POST',
    body: JSON.stringify({
      account: store.account.address,
      collection,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  const json = await response.json() as ApiResponse<string[]>;
  return json.data;
};


export const getSeimuraiMerkleProofData = async (collection: CollectionAddress, wallet: WalletAddress, launchpad: Launchpad) => {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/launchpads/merkle${qs.stringify({ collection, wallet, launchpad, }, true)}`);
  const json = await response.json() as ApiResponse<{
    name: string;
    isWhitelisted: boolean;
    merkleProof: any[] | null;
    hashedAddress: number[] | null;
  }[]>;
  return json;
};


export const getMRKTMintMessage = async (collection: CollectionAddress, wallet: WalletAddress, group: string) => {
  let retries = 0;
  // TODO: Perhaps moves this to the backend like for Frensei?
  while (true) {
    const response = await fetch(`https://api.app.mrkt.exchange/launchpad/get_mint_msg${qs.stringify({ collection_address: collection, group, recipient: wallet }, true)}`);
    if (!response.ok) {
      if (retries < 5) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        retries++;
        continue;
      } else throw new Error(`Failed to fetch MRKT mint message: ${response}`)
    }
    const json = await response.json();
    return json;
  }
};

export const getFrenseiMintMessage = async (collection: CollectionAddress, wallet: WalletAddress, phaseId: number) => {
  /**
   * @note
   * We use the API backend to make the request on behalf of the user because of CORS
   * cross-site limitations.
   */
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/launchpads/mintMessage${qs.stringify({
    collection,
    wallet,
    phaseId,
    launchpad: 'FRENSEI'
  }, true)}`);
  const json = await response.json() as ApiResponse<{ mint: any }>;
  return json.data;
};


export const checkSpecialWhitelistAccess = async (collection: CollectionAddress, wallet: WalletAddress, launchpad: 'MRKT' | 'FRENSEI') => {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/launchpads/whitelist${qs.stringify({ collection, wallet, launchpad }, true)}`);
  const json = await response.json() as ApiResponse<Record<string, boolean>>;
  return json.data;
};


export const updateMinted = async (account: string, collection: CollectionAddress, minted: Tx['minted']) => {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/minter/updateMinted`, {
    method: 'POST',
    body: JSON.stringify({ minted, collection, account }),
    headers: { 'Content-Type': 'application/json' },
  });
  const json = await response.json();
};


export const joinWaitlist = async (address: string) => {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/waitlist/join`, {
    method: 'POST',
    body: JSON.stringify({ address }),
    headers: { 'Content-Type': 'application/json' },
  });
  return await response.json() as ApiResponse<{}>;
};

export async function getSeiUSDPrice() {
  try {
    type Response = { "sei-network": number };
    const response = await fetch('https://api.leapwallet.io/market/prices/ecosystem?currency=USD&ecosystem=cosmos-ecosystem');
    const data: Response = await response.json();
    return data['sei-network'];
  } catch (error) {
    console.error('Failed to fetch SEI price', error);
    return 0;
  }
}

export async function deleteMint(collection: CollectionAddress) {
  const config = useRuntimeConfig();
  const store = useStore();
  const response = await fetch(`${config.public.apiUrl}/minter/deleteMint`, {
    method: 'POST',
    body: JSON.stringify({ account: store.account.address, collection }),
    headers: { 'Content-Type': 'application/json' },
  });
  return await response.json();
}

export async function comfirmPayment(address: string, txHash: string, planId: 'MONTHLY' | 'WEEKLY', usdAmount: string, seiAmount: string) {
  const config = useRuntimeConfig();
  const response = await fetch(`${config.public.apiUrl}/accounts/payment`, {
    method: 'POST',
    body: JSON.stringify({ address, txHash, planId, usdAmount, seiAmount }),
    headers: { 'Content-Type': 'application/json' },
  });
  return await response.json() as ApiResponse<{
    active: boolean, until: string | Date
  }>;
}

export async function getPorfolio() {

  const store     = useStore();
  const config    = useRuntimeConfig();
  const addresses = _.uniq(store.wallets.map(w => w.address));
  const chunks    = _.chunk(addresses, 5);
  const portfolio: MultiWalletPortfolio = {
    collections: [],
    items: [],
  };


  /**
   * Use chunking because when the user first loads
   * his portfolio most of his wallets will need to be
   * initialized. By spreading it to multiple requests
   * we avoid timeout errors.
   *
   * TODO: There is probably a better way to do this.
   */
  await PromisePool
    .for(chunks)
    .withConcurrency(5)
    .process(async (chunk) => {

      const response  = await fetch(`${config.public.apiUrl}/portfolio`, {
        method: 'POST',
        body: JSON.stringify({ addresses: chunk }),
        headers: { 'Content-Type': 'application/json' },
      });
      const { data: portfolioFragment } = await response.json() as ApiResponse<MultiWalletPortfolio>;
      portfolio.collections.push(...portfolioFragment.collections);
      portfolio.items.push(...portfolioFragment.items);
    });

  // Remove any potential duplicates
  portfolio.collections = _.uniqBy(portfolio.collections, 'address');

  return portfolio;
}
